import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	dealerInfo: {
		name: "hamiltonsrv",
		homeBreadcrumb: "RV Dealer",
		themeData: {
			dealerAccent: {
				bg: "#555555",
				bgHover: "#5c5c5c",
				fg: "#FFF",
				fgHover: "#FFF",
				base: { bg: "#333", fg: "#FFF", light: "#aaa" },
				offset: "#fff",
				highlight: {
					bg: "#10994a",
					bgHover: "#1fa353",
					fg: "#FFF",
				},
				homeUrl: "https://dfm-cdn.com/static/4/homepage-background.jpg",
				footerUrl: "static/4/footer-img-s.png",
			},
		},
	},
};
