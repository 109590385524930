import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	dealerInfo: {
		name: "rvworldmi",
		homeBreadcrumb: "RV Dealer",
		themeData: {
			dealerAccent: {
				bg: "#666666",
				bgHover: "#6f6f6f",
				fg: "#FFF",
				fgHover: "#FFF",
				base: { bg: "#333", fg: "#FFF", light: "#aaa" },
				offset: "#fff",
				highlight: {
					bg: "#ea1d1d",
					bgHover: "#f21e1e",
					fg: "#fff",
				},
				homeUrl: "https://dfm-cdn.com/static/9/homepage-background.jpg",
				footerUrl: "static/9/footer-img-s.png",
			},
		},
	},
};
