import { Component, Inject, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ImagePipe } from "@common/pipes/image";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { IPageData } from "@model/page-data";
import { RestService } from "../rest.service";

@Component({
	selector: "cm-floor-plan-types",
	templateUrl: "./floor-plan-types.component.html",
	styleUrls: ["./floor-plan-types.component.scss"],
	encapsulation: ViewEncapsulation.None,
	providers: [ImagePipe],
})
export class FloorPlanTypesComponent implements OnInit {
	@Input() data: any;
	service: any;
	sendInfo: any;
	featuredFloorPlanTypes: any[] = [];
	floorPlanTypes: any[] = [];
	faChevronRight = faChevronRight;

	constructor(
		private rest: RestService,
		private imagePipe: ImagePipe,
		@Inject("PAGE_DATA") private pageData: IPageData,
		private transfer: TransferRxService,
	) {}

	ngOnInit() {
		this.transfer
			.transfer$("GetConditions", () => this.rest.init("statement").post$("GetConditions", {}))
			.subscribe((response: any) => {
				let url = "";

				for (const condition of response.results) {
					if (condition.condition.toLowerCase() === "new") {
						url = condition.content_page_url + "?vehicle_sectionid=";
					}
				}
				this.transfer
					.transfer$("GetVehicleSections", () => this.rest.init("statement").post$("GetVehicleSections", {}))
					.subscribe((response: any) => {
						for (const row of response.results) {
							const imgTitle = row.vehicle_section.replace(" ", "-");

							this.floorPlanTypes.push({
								title: row.vehicle_section,
								link: url + row.vehicle_sectionid,
								count: row.listing_count,
								floorPlanImg: {
									src: this.imagePipe.transform(
										"static/" +
											this.pageData.appInfo.data.siteConfigId +
											"/" +
											imgTitle.toLowerCase() +
											".png",
										"t",
									),
									alt: row.vehicle_section + " Floor Plan",
								},
							});
						}

						this.floorPlanTypes = this.floorPlanTypes.filter((x: any) => x.title !== "Mud Room");

						this.insertFeaturedFloorPlanType(
							this.floorPlanTypes.splice(
								this.floorPlanTypes.findIndex((x) => x.title === "Front Living"),
								1,
							)[0],
						);
						this.insertFeaturedFloorPlanType(
							this.floorPlanTypes.splice(
								this.floorPlanTypes.findIndex((x) => x.title === "Rear Living"),
								1,
							)[0],
						);
						this.insertFeaturedFloorPlanType(
							this.floorPlanTypes.splice(
								this.floorPlanTypes.findIndex((x) => x.title === "Bunkhouse"),
								1,
							)[0],
						);
					});
			});
	}

	/**
	 * Insert a featured vehicle section
	 *
	 * @param type
	 */
	private insertFeaturedFloorPlanType(type: any) {
		if (!type) {
			return;
		}

		const imgTitle = type.title.replace(" ", "-");

		switch (type.title) {
			case "Front Living":
				type.description =
					"Spacious layout options, sometimes with front windshields. Large slide-outs extend to the nearby kitchen for added space. Master bedrooms are typically located in the rear of the RV, for a quieter sleeping experience in back-in campsites.";
				break;
			case "Rear Living":
				type.description =
					"Typically feature large picture windows in the RV’s rear for a lovely view. These tend to have front suites, and sometimes dual entry doors for easy bathroom access. Most have exceptionally large exterior passthrough storage compartments.";
				break;
			case "Bunkhouse":
				type.description =
					"Perfect for large families. Rear bunkhouse models typically feature large separate rooms, sometimes with additional half baths, and large total sleeping capacities. Models with loft bunks may have outside kitchens for cooking convenience.";
				break;
		}

		this.featuredFloorPlanTypes.push(
			Object.assign(type, {
				floorPlanImg: {
					src: this.imagePipe.transform(
						"static/" + this.pageData.appInfo.data.siteConfigId + "/" + imgTitle.toLowerCase() + ".png",
						"s",
					),
					alt: type.vehicle_section + " Floor Plan",
				},
				trailerImg: {
					src: this.imagePipe.transform(
						"static/" +
							this.pageData.appInfo.data.siteConfigId +
							"/" +
							imgTitle.toLowerCase() +
							"-trailer1.png",
						"s",
					),
					alt: type.title + " Trailer",
				},
			}),
		);
	}
}
