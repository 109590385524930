import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { Window2Service } from "@common/services/window2.service";
import { MenuHelperService } from "@core/app/menu-helper.service";
import { UserService } from "@core/app/user.service";
import { IPageData } from "@model/page-data";
import { combineLatest, Observable, of, Subject } from "rxjs";
import { concat, distinctUntilChanged, map, shareReplay, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-header-design-force",
	template: `
		<header
			class="header"
			[ngClass]="{ 'fixate-header': fixed$ | async }"
			itemscope
			itemtype="http://schema.org/WPHeader"
		>
			<div class="cm-header-container" itemscope itemtype="http://schema.org/Organization">
				<meta *ngIf="logoUrl" itemprop="logo" [content]="logoUrl | image" />
				<meta itemprop="url" [content]="siteUrl" />
				<meta itemprop="name" [content]="dealer" />
				<div class="container-fluid main-holder">
					<div class="row d-flex align-items-center main">
						<div class="d-none d-sm-none d-md-none d-lg-flex col-lg-4"></div>
						<div class="col-6 col-lg-4 text-lg-center logo py-2">
							<a routerLink="/" class="d-inline-block">
								<img *ngIf="logoUrl; else noLogo" [src]="logoUrl | image : 'm'" class="img-fluid" />
								<ng-template #noLogo
									><span>{{ dealer }}</span></ng-template
								>
							</a>
						</div>
						<div class="col-6 col-lg-4 text-right">
							<a href="tel:{{ location.org_phone_number }}" class="h3 theme-bg-as-fg"
								><strong>{{ location.org_phone_number | tel }}</strong></a
							>
						</div>
					</div>
				</div>
				<img src="https://dfm-cdn.com/static/12/1-header.png" class="img-fluid v-align-initial" />
			</div>
		</header>
	`,
	styles: [
		`
			:host {
				display: inline-block;
				width: 100%;
			}
			header {
				position: fixed;
				top: 0;
				width: 100%;
				margin-bottom: 3px;
				z-index: 135;
				transition: top 0.3s ease-in-out;
			}
			.main-holder {
				background: #fff;
			}
			.main-holder {
				height: 64px;
			}
			.logo img {
				max-height: 50px;
			}
			.v-align-initial {
				vertical-align: initial;
				display: block;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDesignForceComponent {
	fixed$: Observable<boolean>;
	siteUrl: string;
	dealer: string;
	logoUrl: string;
	menus: any;
	loadS: Subject<void> = new Subject();
	searchValues$: Observable<any>;
	searchOptions: any;
	location = this.pageData.locations[0];
	constructor(
		private router: Router,
		http: HttpClient,
		@Inject("PAGE_DATA") private pageData: IPageData,
		window2Service: Window2Service,
		menuHelperService: MenuHelperService,
		user: UserService,
	) {
		this.fixed$ = window2Service.scrollY$.pipe(
			map((scrollY) => scrollY > 46),
			distinctUntilChanged(),
		);

		this.siteUrl = "https://" + this.pageData.host + "/";
		this.dealer = this.pageData.headerData.queries.GetDealerInfo[0].dealer;
		this.logoUrl =
			this.pageData.headerData.queries.GetDealerInfo[0].img_dir +
			this.pageData.headerData.queries.GetDealerInfo[0].img_file;
		this.searchValues$ = combineLatest(user.loggedIn$, user.isCustomer$, this.loadS).pipe(
			map(([loggedIn, isCustomer]) =>
				loggedIn && !isCustomer
					? this.pageData.searchUrls.salesSearchResults
					: this.pageData.searchUrls.searchResults,
			),
			switchMap((url) => of(null).pipe(concat(http.get(url)))),
			shareReplay(),
		);

		this.searchOptions = {
			disabled: false,
			focusFirst: false,
			focus: () => this.loadS.next(),
			changed: (model: any, event: any) => {
				if (event && event.keyCode === 13) {
					if (model && model.hasOwnProperty("link")) {
						this.router.navigateByUrl(model.link);
					} else if (model && model.hasOwnProperty("item")) {
						this.router.navigateByUrl(model.item.link);
					} else if (model.length > 0) {
						this.router.navigateByUrl("/search?s=" + model);
					}
				} else if (model && (model.hasOwnProperty("link") || model.hasOwnProperty("item"))) {
					if (model.link) {
						this.router.navigateByUrl(model.link);
					} else if (model.item && model.item.link) {
						this.router.navigateByUrl(model.item.link);
					}
				}
			},
			enterKeySubmit: true,
			placeholder: "What are you looking for?",
		};
		this.menus = menuHelperService.getMenu();
	}
}
