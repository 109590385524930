import { Component, Input, OnInit, OnChanges, SimpleChange } from "@angular/core";
import { ConfigService } from "@core/app/config.service";

@Component({
	selector: "cm-image",
	templateUrl: "./image.component.html",
	styleUrls: ["./image.component.scss"],
})
export class ImageComponent implements OnInit, OnChanges {
	/**
	 * Sets the value of POLL_INTERVAL for the IntersectionObserver polyfill. Only affects Safari and IE.
	 * See the [polyfill readme](https://github.com/w3c/IntersectionObserver/tree/master/polyfill) for more info.
	 */
	@Input() intersectionPollInterval?: number;

	image: any;
	buckets = {
		dms: "dms/",
		img: "img/",
		wpContent: "wp-content/",
	};
	imgStaticUrl = "static/";
	@Input("obj") obj!: any;
	showText: boolean = false;

	constructor(private config: ConfigService) {}

	/**
	 * Build attributes for the image
	 */
	buildAttrs(): void {
		if (!this.image.src && this.image.id) {
			let src = this.image.id;
			src = src.replace(/-i\.|-t\.|-s\.|-m\.|-l\.|-o\.|-lc\./gi, ".");
			const index = src.lastIndexOf(".");

			if (-1 !== index && this.obj.size && this.obj.size !== "a") {
				src = src.substr(0, index) + "-" + this.obj.size + src.substr(index);
			}

			if (!src.startsWith("http")) {
				if (this.config.cdnRootPath) {
					// case where img url does not contain full path to S3 and is not an absolute img url reference
					if (!src || src === "cdnRootPath") {
						src = null;
					} else if (src.includes(this.buckets.dms) || src.includes(this.buckets.img)) {
						src = this.config.cdnRootPath + src;
					} else if (src.includes(this.buckets.wpContent)) {
						src = this.config.cdnRootPath + this.buckets.dms + src;
					} else if (!src.includes(this.config.imgStaticUrl)) {
						src = this.config.cdnRootPath + this.config.imgStaticUrl + src;
					} else {
						src = this.config.cdnRootPath + src;
					}
				} else {
					if (!src || src === "cdnRootPath") {
						src = null;
					} else if (src.includes(this.buckets.dms) || src.includes(this.buckets.img)) {
						src = "https://dfm-cdn.com/" + src;
					} else if (src.includes(this.buckets.wpContent)) {
						src = "https://dfm-cdn.com/" + this.buckets.dms + src;
					} else if (!src.includes(this.imgStaticUrl)) {
						src = "https://dfm-cdn.com/" + this.imgStaticUrl + src;
					} else {
						src = "https://dfm-cdn.com/" + src;
					}
				}
			}
			this.image.src = src;
		}

		this.image.attrs = {
			alt: this.image.altText || this.image.text || "",
			class: this.image.attrClass,
			src: this.image.src || "",
			title: this.image.titleText || this.image.altText || this.image.text || "",
		};
	}

	/**
	 * Setup this Image's Properties
	 *
	 * @param img
	 */
	setupImg(img: any): void {
		img = img || {};
		this.image = img;

		if (this.image.hasOwnProperty("fullscreen") && this.image.fullscreen) {
			this.image.class += "fs";
		}

		if (this.image.hasOwnProperty("span") && this.image.span) {
			this.image.rootType = "span";
		} else {
			this.image.rootType = "div";
		}

		this.buildAttrs();
	}

	ngOnInit() {
		this.setupImg(this.obj);
	}

	ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
		if (changes.obj && !changes.obj.isFirstChange()) {
			this.setupImg(changes.obj.currentValue);
		}
	}
}
