import { Component, ChangeDetectionStrategy, HostListener } from "@angular/core";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { Router } from "@angular/router";

// TODO: generate this component at build time to get rid of the ngSwitch, so the unused templates can be treeshaken

@Component({
	selector: "cm-header-anthony",
	template: `
		<header id="nav-header" class="header container-fluid {{ addClass ? 'sticky' : '' }}">
			<div class="row align-items-center" itemscope itemtype="http://schema.org/WPHeader">
				<div class="col-6 d-md-none py-3">
					<a routerLink="/">
						<img src="https://dfm-cdn.com/static/16/al-1-s.png" class="img-fluid" />
					</a>
				</div>
				<div class="d-none d-md-block col-md-4">
					<cm-search
						placeholder="Enter city, zip, county, or neighborhood"
						buttonClass="primary"
						[buttonIcon]="faSearch"
						buttonText=""
						(search)="search($event)"
					></cm-search>
				</div>
				<div class="col-auto col-md-8 menu d-flex justify-content-end">
					<cm-menu2 [appMenuid]="7" rootStyle="inline" subStyle="unstyled"></cm-menu2>
				</div>
			</div>
		</header>
	`,
	styleUrls: ["./header-anthony.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderAnthonyComponent {
	@HostListener("window:scroll", ["$event"])
	faSearch = faSearch;
	addClass = false;

	constructor(private router: Router) {}

	onWindowScroll() {
		this.addClass = window.pageYOffset > 100;
	}

	search(text: string) {
		this.router.navigateByUrl(`/listings?keywords=${text}`);
	}
}
