import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	dealerInfo: {
		name: "allseasonsrv",
		homeBreadcrumb: "RV Dealer",
		themeData: {
			dealerAccent: {
				bg: "#fe2026",
				bgHover: "#ed1c22",
				fg: "#FFF",
				fgHover: "#FFF",
				base: { bg: "#333", fg: "#FFF", light: "#aaa" },
				offset: "#fff",
				highlight: {
					bg: "#81b60a",
					bgHover: "#8ec70c",
					fg: "#FFF",
				},
				homeUrl: "https://dfm-cdn.com/static/6/homepage-background.jpg",
				footerUrl: "static/6/footer-img-s.png",
			},
		},
	},
};
